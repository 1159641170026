export function numToFormat(input) {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return;
  }
  const str = typeof input !== 'string' ? input.toString() : input;
  const arr = str.split('.');
  const reg = /(?=(\B\d{3})+$)/g;
  arr[0] = arr[0].replace(reg, ',');
  return arr.join('.');
}
