import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Prefix } from '../../utility';
import './index.scss';
function Section(props) {
  const { className, children, title, linkText, linkUrl, desc, ...rest } = props;
  const cls = classNames(className, `${Prefix}-section `);
  return (
    <section className={cls} {...rest}>
      <div className={`${Prefix}-section__inner`}>
        {(!!title || !!desc || !!linkText) && (
          <header className={`${Prefix}-section__hd`}>
            {!!title && <h2 className={`${Prefix}-section__title`}>{title}</h2>}
            {(!!desc || !!linkText) && (
              <div className={`${Prefix}-section__sub`}>
                <span className={`${Prefix}-section__desc`}>{desc}</span>
                <span className={`${Prefix}-section__links`}>
                  <a href={linkUrl} className="tp-link tpm-link tp-link--arrow">
                    <span className="tp-link__inner">{linkText}</span>
                  </a>
                </span>
              </div>
            )}
          </header>
        )}
        <div className={`${Prefix}-section__bd`}>{children}</div>
      </div>
    </section>
  );
}

Section.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.element,
};

export default Section;
