import React from 'react';
import { mPrefix } from '../../utility';
import Media from '../../components/Media';
import { Row, Col } from '../../components/Grid';
import PropTypes from 'prop-types';
import './index.scss';
export default function MediaRecommend(props) {
  const { data = [] } = props;
  return (
    <div className={`${mPrefix}-mediaRecommend`}>
      <Row gutter={[24, 24]}>
        {data.map((item, index) => (
          <Col colSpan={12} key={`col-${index}`}>
            <Media data={item} radius card shadow hover horizon opposite vertical />
          </Col>
        ))}
      </Row>
    </div>
  );
}

MediaRecommend.propTypes = {
  data: PropTypes.array,
};
