import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Prefix } from '../../utility';
import './index.scss';
import PropTypes from 'prop-types';
export default function Button(props) {
  const {
    className, // 自定义类名
    style, // 自定义样式
    type, // 按钮，a 标签
    ripple,
    primary,
    href,
    empty,
    loading,
    disabled,
    onClick = () => {},
    ...rest // 自定义其他属性
  } = props;
  // button class
  const cls = classNames(`${Prefix}-button`, className, {
    [`${Prefix}-button--primary`]: primary,
    [`${Prefix}-button--empty`]: empty,
  });

  const anchoRef = useRef(null);
  const [anStatus, setAnStatus] = useState(false);
  const [coordinate, setCoordinate] = useState([0, 0, 0]);
  const [anSty, setAnSty] = useState({});
  // dom 信息
  const domInfo = () => {
    const anchorRect = anchoRef.current;
    const domMax = anchorRect.clientWidth > anchorRect.clientHeight ? anchorRect.clientWidth : anchorRect.clientHeight;
    const domX = anchorRect.getBoundingClientRect().x;
    const domy = anchorRect.getBoundingClientRect().y;
    setCoordinate([domX, domy, domMax]);
  };

  // 点击 波纹效果
  const conClick = (event) => {
    const e = event || window.event;
    // const x =
    //   e.pageX ||
    //   document.documentElement.scrollLeft +
    //     document.body.scrollLeft +
    //     e.clientX;
    // const y =
    //   e.pageY ||
    //   document.documentElement.scrollTop - document.body.scrollTop + e.clientY;
    const x = e.clientX;
    const y = e.clientY;
    setAnSty({
      width: 2 * coordinate[2],
      height: 2 * coordinate[2],
      left: -(coordinate[2] - (x - coordinate[0])),
      top: -(coordinate[2] - (y - coordinate[1])),
    });
    setAnStatus(true);
    onClick();
  };
  // 波纹 class
  const anCls = classNames('ripple-effect__inner', {
    'is-actived': anStatus,
  });
  useEffect(() => {
    domInfo();
    let timer = null;
    if (anStatus) {
      timer = setTimeout(() => {
        setAnStatus(false);
      }, 500);
    }
    return () => {
      !!timer && clearTimeout(timer);
    };
  }, [anStatus]);
  if (type === 'link') {
    return (
      <a className={cls} href={href} style={style} {...rest} onClick={onClick}>
        {props.children}
      </a>
    );
  }
  return (
    <button type="button" className={cls} style={style} {...rest} onClick={conClick} ref={anchoRef}>
      {!!ripple && (
        <span className="ripple-effect">
          <span className={anCls} style={anSty}></span>
        </span>
      )}
      {props.children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  ripple: PropTypes.bool,
  primary: PropTypes.bool,
  href: PropTypes.string,
  empty: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.element,
};
