import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mPrefix } from '../../utility';
import PropTypes from 'prop-types';
import './index.scss';
import useClientType from '../../hooks/useClientType';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default function CarouseBanner(props) {
  const {
    data = [],
    spaceBetween = 0,
    slidesPerView = 1,
    showPagination = false,
    autoplay = false,
    loop = true,
    navigation = true,
  } = props;
  // 是否可以拖动滚动
  const { isMobile } = useClientType();
  // useEffect(() => {
  //   console.log('bbb');
  //   if (bannerVideo?.current) {
  //     const video = bannerVideo.current;
  //     video.onended = function () {
  //       console.log('end');
  //       this.play();
  //     };
  //   }
  // }, []);
  return (
    <section className={`${mPrefix}-carouseBanner`}>
      <Swiper
        className={`${mPrefix}-carouseBanner__swiper`}
        spaceBetween={spaceBetween}
        pagination={
          showPagination && {
            el: `.${mPrefix}-carouseBanner__pagination`,
            bulletClass: `${mPrefix}-carouseBanner__pagination-item`,
            bulletActiveClass: 'is-actived',
            clickable: true,
          }
        }
        navigation={
          navigation && {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'disabled',
          }
        }
        slidesPerView={slidesPerView}
        autoplay={
          // eslint-disable-next-line prettier/prettier
          autoplay
          // eslint-disable-next-line prettier/prettier
          && data.length > slidesPerView && {
            delay: 3000,
            speed: 3000,
            observer: true,
            observeParents: true,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            autoplayDisableOnInteraction: false,
          }
        }
        loop={loop && data.length > slidesPerView}
        mousewheel={true}
      >
        {data.map((item, index) => (
          <SwiperSlide className={`${isMobile ? '' : 'swiper-no-swiping'}`} key={`swiper-${index}`}>
            <div className={`${mPrefix}-carouseBanner__item`}>
              <div className={`${mPrefix}-carouseBanner__item-img`}>
                {/* <img src={item.imgPc} alt={item.imgPc} className="show-pc" />
                <img src={item.imgMobile} alt={item.imgMobile} className="show-moblie" /> */}
                <video
                  autoPlay="autoplay"
                  poster={item.videopic}
                  loop="loop"
                  muted="muted"
                  // eslint-disable-next-line react/no-unknown-property
                  x5-video-player-type="h5-page"
                  // eslint-disable-next-line react/no-unknown-property
                  x5-video-orientation="landscape|portrait"
                  // eslint-disable-next-line react/no-unknown-property
                  webkit-playsinline="true"
                  // eslint-disable-next-line react/no-unknown-property
                  playsinline="true"
                >
                  <source src={item.video} type="video/mp4" />
                </video>
              </div>
              <div className={`${mPrefix}-carouseBanner__item-info `}>
                <div className={`${mPrefix}-carouseBanner__item-detail show-pc`}>
                  <div className={`${mPrefix}-carouseBanner__item-detail--title`}>{item.title}</div>
                  <div className={`${mPrefix}-carouseBanner__item-detail--des`}>{item.des}</div>
                  <div className={`${mPrefix}-carouseBanner__item-detail--info`}>
                    「至信链」开放联盟链是合规、开放、易用的底层区块链平台，企业用户与个人开发者既可以快速调用已封装好的链服务，
                  </div>
                  <div className={`${mPrefix}-carouseBanner__item-detail--info`}>
                    也可以自行编写智能合约并部署，用以服务各类客户。
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {data.length > slidesPerView && (
        <div className={`${mPrefix}-carouseBanner__pagination`}>
          <div className={`${mPrefix}-carouseBanner__pagination-item`}></div>
        </div>
      )}
    </section>
  );
}

CarouseBanner.propTypes = {
  data: PropTypes.array,
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  showPagination: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  navigation: PropTypes.bool,
};
