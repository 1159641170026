import React from 'react';

export default function Service() {
  return (
    <div className="z-doc">
      <h1 className="z-center">至信链数字藏品服务声明</h1>
      <p>本声明最近更新于：2021年12月09日。</p>
      <p>
        欢迎您使用至信链数字藏品服务（以下简称“本服务”）。
        至信链对您出具的声明/政策/通知等文件，将构成您与至信链就您使用本服务所达成的全部共识。
        <b className="z-underline">
          您在使用本服务之前，请详细阅读本声明，若您使用本服务，即表示您已阅读并同意且接受。
          本声明中需要您特别注意的内容，我们已经使用加粗方式特别标注，请您重点阅读！
        </b>
      </p>
      <h2>1. 相关定义</h2>
      <p>
        （1） “至信链”是腾讯公司联合外部生态伙伴，基于国产开源自主可控的“长安链”技术底层，共同发布的区块链技术平台。
      </p>
      <p>
        （2）「应用平台」：本文所指「应用平台」是指您实际购买、管理数字藏品的应用、平台或程序，
        至信链作为区块链底层为「应用平台」提供数字藏品服务能力。「应用平台」通过API接口
        调用至信链数字藏品服务，为数字藏品提供唯一性证明和链上流转记录。
      </p>
      <h2>2. 至信链服务说明</h2>
      <h3>2.1【数字藏品】</h3>
      <p>
        <b>
          （1）您理解并同意至信链作为技术平台的中立性，至信链仅为特定数字藏品提供唯一性证明和链上流转记录，
          对于「应用平台」数字藏品的制作、发行、宣传等行为不承担任何审核义务，亦不承担任何责任。
          数字藏品的权利内容、价格波动等问题，与至信链无关，至信链不对此承担任何责任。
        </b>
      </p>
      <p className="z-underline">
        （2）您理解基于区块链操作的不可撤销属性，当您使用至信链相关服务时，您应当自行承担
        因您操作失误而导致的后果，至信链无法回滚、撤销已经完成的数字藏品转移操作或删除特定区块链记录。
      </p>
      <h3>2.2【至信链地址】</h3>
      <p>
        （1）至信链将基于您的个人实名信息为您匹配至信链地址，至信链地址是通过哈希算法计算的特定
        哈希值，在至信链上表现为无规则的数字序列，通过哈希值不能反推任何您的个人信息。
      </p>
      <p>
        （2）「应用平台」在您申请使用数字藏品相关服务时，「应用平台」向至信链发出请求，由至信链为您匹
        配唯一的至信链地址。基于该地址，您可以管理您的数字藏品。
      </p>
      <p className="z-underline">
        （3）基于区块链数据结构，至信链产生的地址信息从技术上无法删除。我们将按照《中华人民共和国个人信息保护法》
        的要求对地址信息采取妥善的保护措施。
      </p>
      <h3>2.3【区块链记录】</h3>
      <p>
        （1）您理解并同意，在您使用「应用平台」服务过程中，将可能在至信链上产生区块链记录，
        区块链记录反映了特定数字藏品在不同至信链地址间的流转记录。
      </p>
      <p>
        （2）区块链记录是通过哈希算法计算的特定哈希值，在至信链上表现为无规则的数字序列，
        通过哈希值不能反推任何您的个人信息。
      </p>
      <p className="z-underline">
        （3）您理解并同意数字藏品的唯一性证明依赖于区块链记录，您对特定数字藏品是否享有
        权利以及权利内容将以区块链记录作为主要判断依据。
      </p>
      <p className="z-underline">（4） 您理解并同意相关区块链记录可能被用作数字藏品的流转信息在「应用平台」上展示。</p>
      <p>
        （5）基于区块链数据结构，至信链产生的区块链记录从技术上无法删除。
        我们将按照《中华人民共和国个人信息保护法》的要求对区块链记录采取妥善的保护措施。
      </p>
      <h2>3. 您的承诺</h2>
      <h3>3.1. 【使用行为承诺】</h3>
      <p className="z-underline">
        {' '}
        <b>
          您同意在使用至信链及其内容时，遵守国家法律法规、社会公共道德。您不得利用至信链
          及其内容从事制作、查阅、复制和传播任何违法、侵犯他人权益等扰乱社会秩序、破坏社会稳定的行为，
          亦不得利用至信链及其内容从事任何危害或试图危害计算机系统及网络安全的活动。
        </b>
      </p>
      <p>您访问或使用至信链服务，或允许他人访问或使用服务时，不得有下列行为：</p>
      <p>（1） 侵犯他人权益，包括但不限于隐私权或知识产权；</p>
      <p>
        （2）自己从事、鼓动或协助他人进行任何违法行为，包括不限于任何下述活动：以任何方式伤害或企图伤害
        未成年人、色情信息传播、非法赌博、金融诈骗、洗钱、网络攻击、网络钓鱼、私自拦截任何系统、
        程序或数据，未经许可监控服务数据或流量等；
      </p>
      <p>
        （3）传输、提供、上传、下载、使用或再使用、散布或分发任何非法、侵权、攻击性、
        有害的内容或材料，包括但不限于在如下“禁止的内容”中所列的内容或材料；
      </p>
      <p>
        （4）传输、发送或上传任何包含病毒、蠕虫、特洛伊木马、网络定时炸弹、键盘记录器、间谍软件、
        广告软件或任何其他有害程序或类似的旨在对任何计算机硬件或软件的安全产生不利影响的计算机代码；
      </p>
      <p>（5） 破坏至信链服务或系统的安全性、完整性；</p>
      <h3>3.2. 【禁止的内容】</h3>
      <p>下列禁止内容适用于您通过至信链服务开展的任何活动，您的活动内容不得：</p>
      <p>（1） 具有诽谤性、淫秽性、攻击性、仇恨性或煽动性；</p>
      <p>（2）宣扬种族、性别、宗教、国籍、残疾、性取向等问题的歧视性内容；</p>
      <p>（3）侵犯知识产权；</p>
      <p>（4）可能是虚假、欺骗或误导的；</p>
      <p>（5）促进、提倡、煽动或协助任何非法活动；</p>
      <p>（6） 威胁、滥用、侵犯第三方隐私；</p>
      <h3>3.3. 【违规处置措施】</h3>
      <p>
        我们虽无义务调查任何违反本声明、或任何滥用服务的行为，但我们保留此权利。
        如果您有任何违反或涉嫌违反本声明的行为，我们有权根据自己的判断采取下述措施而无须向您承担任何责任，包括：
      </p>
      <p>（1） 调查违反本声明或滥用服务的行为；</p>
      <p>（2） 调查或防止安全威胁、欺诈或其他具有危害性的活动；</p>
      <p>（3） 修改、禁止访问或删除任何具有危害性的内容或材料，包括但不限于您的内容；</p>
      <p>（4） 向您发出警告，要求您纠正错误；</p>
      <p>（5） 提起法律诉讼，要求您赔偿因您的违约行为而引起的损失(包括但不限于合理的管理及法律费用)</p>
      <p>（6） 对您采取进一步的法律行动；</p>
      <p>（7） 立即暂停或终止您的服务；</p>
      <p>（8） 向适当的执法机构或其他适当的第三方报告您的违法行为。</p>
      <h2>4. 隐私保护</h2>
      <h3>4.1. 【数据收集的类型】</h3>
      <p>
        （1）「应用平台」通过API接口将您的个人实名信息，包括姓名、身份证号、手机号传输到至信链，
        至信链会验证并存储上述实名信息。
      </p>
      <p>
        （2）您理解并同意上述信息均是为您提供服务之必需。如您选择不提供或不同意我们收集、使用以上信息，
        将导致您无法正常使用我们的服务，我们将无法为您服务。
      </p>
      <h3>4.2. 【实名信息处置措施】</h3>
      <p>
        （1）对于上述实名信息，我们将在您使用上述服务期间留存。如您不再继续使用至信链相关服务或
        在您主动删除个人数据后，我们将不再保留你的上述数据。上述所有实名信息均存储于中华人民共和国境内。
      </p>
      <p>
        （2）对于您的上述实名信息，我们将采取相关安全保障措施，包括但不限于加密，脱敏等。
        至信链不对外进行共享、转让、公开披露，除非依据您的指示或法律法规的强制性要求。
        根据适用的法律法规，您具有查阅、复制、更正、删除或移植您上述实名信息的权利。
      </p>
      <h2>5.免责事由</h2>
      <p className="z-underline">
        <b>
          在您使用「应用平台」服务时，所有您在「应用平台」进行的操作行为系您的个人行为，
          有约束力的合同关系在您和您的相对方之间建立，与至信链无关。至信链对因您在「应用平台」
          上的行为所引起的一切风险、责任、损失、费用不承担任何责任。
        </b>
      </p>
      <h2>6. 其他</h2>
      <h3>6.1. 【通知】</h3>
      <p>
        您了解并同意，为适应政策和业务的需要，我们将在必要时对本服务声明内容进行更新
        并在至信链官网上公布，请您及时参阅本服务声明更新，开展业务。
      </p>
      <h3>6.2. 【标题说明】</h3>
      <p>各条款前所列索引标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本声明条款的含义或解释。</p>
      <h3>6.3. 【联系我们】</h3>
      <p>
        如您对本声明有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，
        请通过至信链官网的联系方式与我们联系。
      </p>
    </div>
  );
}
