import React from 'react';
import { mPrefix } from '../../utility';
import Media from '../../components/Media';
import { Row, Col } from '../../components/Grid';
import PropTypes from 'prop-types';
import './index.scss';
export default function MediaAdvantages(props) {
  const { data = [] } = props;
  return (
    <div className={`${mPrefix}-mediaAdvantages`}>
      <Row gutter={[90, 90]} overflow>
        {data.map((item, index) => (
          <Col colSpan={6} phone={24} key={`col-${index}`}>
            <Media data={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

MediaAdvantages.propTypes = {
  data: PropTypes.array,
};
