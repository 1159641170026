import certificate1 from './img/about/2019-PCI-DSS安全认证-含TBaaS.png';
import certificate2 from './img/about/2020-可信BaaS测试.png';
import certificate3 from './img/about/2020-可信功能测试.png';
import certificate4 from './img/about/软件著作权.png';
import certificate5 from './img/about/腾讯云BaaS组副组长证书.png';
import certificate6 from './img/about/2019-2020年中国区块链产业应用领域10强企业.png';
import certificate7 from './img/about/2020可信区块链高价值案例-至信链.png';
import certificate8 from './img/about/2020-中国产业区块链创新奖.png';
import certificate9 from './img/about/2020-电子标准化研究院-区块链功能测试证书.png';
import certificate10 from './img/about/2020-电子标准化研究院-区块链性能测试证书.png';

export const CarouselHonorData = [
  { img: certificate1, title: '2019PCI-DSS安全认证' },
  { img: certificate2, title: '可信BaaS测试' },
  { img: certificate3, title: '可信功能测试证书' },
  { img: certificate4, title: '软件著作权' },
  { img: certificate5, title: '腾讯云BaaS组副组长证书' },
  { img: certificate6, title: '2019-2020年中国区块链产业应用领域10强企业' },
  { img: certificate7, title: '2020可信区块链高价值案例奖' },
  { img: certificate8, title: '2020中国产业区块链创新奖' },
  { img: certificate9, title: '2020-电子标准化研究院-区块链功能测试证书' },
  { img: certificate10, title: '2020-电子标准化研究院-区块链性能测试证书' },
];
export const CarouselRecordData = [
  {
    date: '2019',
    list: [
      {
        moon: '7月',
        content:
          '区块链应用生态服务平台「至信链」发布。',
      },
      {
        moon: '12月',
        content:
          '腾讯“原创馆”上线「至信链」版权存证功能。',
      },
    ],
  },
  {
    date: '2020',
    list: [
      {
        moon: '3月',
        content:
          '「至信链」与深圳前海法院、微众银行达成合作，共推“至信云审1.0”系统。',
      },
      {
        moon: '6月',
        content:
          '企鹅号原创文章被侵权后，法官根据「至信链」存证证据当庭宣判被告败诉赔偿。这是国内首个从作品确权到侵权取证全流程采用区块链存证技术的著作权维权案件。',
      },
      {
        moon: '10月',
        content: '「至信链」与中国建设银行总行正式签署合作协议。',
      },
    ],
  },
  {
    date: '2021',
    list: [
      {
        moon: '2月',
        content:
          '「至信链」成为法律科技领域首个适配「长安链」的大型区块链应用平台。',
      },
      {
        moon: '4月',
        content:
          '深圳龙华法院使用“深圳法院区块链证据核验平台”当庭核验在「至信链」上存证的电子证据，并判决原告胜诉。这是全国法院首宗通过“移动微法院”提交区块链证据并自动核验的案件。',
      },
      {
        moon: '7月',
        content: '「至信链」元商品协议正式上线。',
      },
    ],
  },
];
