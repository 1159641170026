import React from 'react';

export default function Identity() {
  return (
    <div className="z-doc">
      <h1 className="z-center">至信链实名认证服务隐私政策</h1>
      <p>更新日期：2021-12-20</p>
      <p>生效日期：2021-12-20</p>
      <br />
      <h2>引言</h2>
      <p>
        “至信链”是腾讯云计算（北京）有限责任公司（以下简称“我们”）提供区块链产品，
        公司注册地为北京市海淀区知春路49号3层西部309。
        至信链是腾讯云联合外部生态伙伴共同发布的区块链开放平台，旨在向社会提供安全、客观、公正的区块链应用服务。
        腾讯云至信链实名认证服务的对接方式包含SDK、H5页面、API能力。
        《至信链实名认证服务隐私政策》主要向数字藏品应用平台（以下简称“应用平台”）
        和其终端用户说明我们如何处理个人信息（处理包括“收集、存储、使用、加工、传输、提供、公开等”。
        <b>
          在注册、接入、使用腾讯云至信链实名认证服务（以下简称“本服务”）产品和/或服务前，
          请“应用平台”和终端用户务必仔细阅读本政策。同时，请“应用平台”将本政策提供给终端用户，
          以便最终用户了解本政策相关内容并获得用户同意。
          <span className="z-underline">
            {' '}
            如果“应用平台”或终端用户不同意本政策，应立即停止接入及使用至信链产品和/或服务。
          </span>
        </b>
      </p>
      <p>
        <span className="z-underline">
          <b>我们对个人敏感信息采用“加黑”的书写方式进行特别提醒。</b>
        </span>
        如对本政策内容有任何疑问、意见或建议，可随时通过本政策第九条提供的方式与我们联系。
      </p>

      <h2>一、如何收集和使用“应用平台”和/或终端用户的个人信息</h2>
      <p>
        我们遵循正当、合法、必要的原则，基于本政策所述的以下目的，收集和使用：
        “应用平台”和/或终端用户主动提供或我们直接收集的个人信息；
        终端用户在使用本服务产品和/或服务过程中产生的个人信息；
        从“应用平台”或者其他第三方获取的“应用平台”和/或终端用户的个人信息。
        我们通常会在征得“应用平台”和/或终端用户同意后收集个人信息。
        我们会在本政策中载明收集的个人信息用途，如果我们要将收集个人信息用于本政策未载明的其它用途，
        我们会以合理的方式向“应用平台”和/或终端用户告知，并在使用前再次征得“应用平台”和/或终端用户的同意。
      </p>
      <h3>（一）“应用平台”和/或终端用户主动提供或我们直接收集的个人信息</h3>
      <p>
        为实现本服务的业务功能，我们可能需要向“应用平台”和/或终端用户收集相关个人信息。
        以下将详细列出本服务的各项业务功能及为实现该功能所需收集的个人信息情况。
      </p>
      <p className="z-bold">
        关于本服务的个人信息处理清单具体如下：
        <br />
        服务方案：实名信息校验
        <br />
        实名要素：手机号、姓名、身份证号
        <br />
        处理方式：我们代理应用平台将其提供的信息主体的个人信息传递给
        合作的权威数据源存储的信息进行比对，得出一致或不一致的结果。
        <br />
        设备权限：无
        <br />
        接入方式：API
      </p>
      <h3>（二）以下情形中，我们收集、使用个人信息无需征得“应用平台”和/或终端用户的授权同意：</h3>
      <p className="z-bold">
        1、为订立、履行合同所必需；
        <br />
        2、为履行法定职责或者法定义务所必需；
        <br />
        3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所需；
        <br />
        4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
        <br />
        5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
        <br />
        6、法律行政法规规定的其他情形。
        <br />
        特别提示：如我们收集的信息无法单独或结合其他信息识别“应用平台”
        和/或终端用户的个人身份，其不属于法律意义上的个人信息。
      </p>
      <h3>（三）个人信息的使用规则</h3>
      <p>
        1、我们会根据本政策和/或与“应用平台”的合同约定，并仅为实现本服务产品和/或服务功能，
        对所收集的个人信息进行处理。若需要将收集的个人信息用于其他目的，
        我们会以合理方式告知“应用平台”和/或终端用户，并在“应用平台”获得终端用户同意后和/或单独获得终端用户同意后进行使用。
        <br />
        2、我们向“应用平台”提供的产品和/或服务停止运营后，或者“应用平台”和/或终端用户撤回个人信息的授权后，
        或者在“应用平台”和/或终端用户注销账号后，我们将会于合理的时间内销毁或匿名化处理从“应用平台”和/或
        终端用户处接收的所有个人信息，除非法律另有规定。
      </p>

      <h2>二、如何使用Cookie等同类技术</h2>
      <p className="z-underline z-bold">
        当“应用平台”访问、登录、使用本服务时，为确保网站正常运转，我们可能会在“应用平台”的
        计算机或移动设备上通过Cookie缓存功能使用相关信息。Cookie通常包含当前登录账号、站
        点地址、浏览次数。运用Cookie技术，我们能够为“应用平台”提供更加周到的个性化服务，并
        允许“应用平台”设定“应用平台”特定的服务选项。
        <br />
        <a
          href="https://privacy.qq.com/document/priview/UzHogYzog73ns7vnu5_vvI3ogYzog73nur866YWN572uIkNvb2tpZeaUv-etliI6Q29va2ll5pS_562W"
          target="_blank"
          rel="noreferrer"
        >
          《Cookie政策说明》
        </a>
      </p>

      <h2>三、如何共享、转让、公开披露“应用平台”和/或终端用户的个人信息</h2>
      <h3>（一）共享</h3>
      <p>
        <span className="z-underline z-bold">
          一般情况下，我们不会与我们的关联公司、合作伙伴及第三方共享“应用平台”和/或终端用
          户的必要的个人信息，除非为实现本政策第一条中所提及目的的需要。
        </span>
        如存在分享的行为，我们会单独征得“应用平台”和/或终端用户的同意，并要求关联公司、合作
        伙伴及第三方提供数据安全能力和信息安全资质（如等级保护测评、信息安全管理体系等）证明。
        在个人敏感信息的处理上，我们会要求第三方采用数据脱敏加密技术，从而更好地保护个人信息。
        如果“应用平台”和/或终端用户对第三方处理个人信息有异议或发现这些第三方存在风险时，请
        按照本政策第九条所述方式联系我们。
        <br />
        我们的关联公司、合作伙伴及第三方如要改变个人信息的处理目的，我们会再次征求“应用平台” 和/或终端用户的同意。
      </p>
      <h3>（二）转让</h3>
      <p className="z-bold">
        <span className="z-underline">
          我们不会将“应用平台”和/或终端用户的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </span>
        1、事先获得“应用平台”和/或终端用户单独同意；
        2、在涉及合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向“应用平台”和/或
        终端用户告知接收方的名称或者姓名和联系方式，并要求接收方继续履行个人信息处理者的义务。
        接收方变更原先的处理目的、处理方式的，我们会要求接收方重新取得“应用平台”和/或终端用户的同意。
      </p>
      <h3>（三）公开披露</h3>
      <p>
        <span className="z-underline z-bold">我们不会公开披露“应用平台”和/或终端用户的个人信息，除非：</span>
        <br />
        1、获得“应用平台”和/或终端用户的单独同意后；
        <br />
        2、在法律法规、法律程序、诉讼或政府主管部门强制要求的情况下。
      </p>

      <h2>四、如何存储“应用平台”和/或终端用户的个人信息</h2>
      <h3>（一）存储方式和期限</h3>
      <p className="z-bold">
        我们会通过安全的方式存储“应用平台”和/或终端用户的信息，包括本地存储、数据库和服务器日志。
        一般情况下，我们只会在为实现本政策所述目的所必需的最短时间内或法律法规规定或个人信息主体
        另行授权同意的条件/范围内存储“应用平台”和/或终端用户的个人信息。但在下列情况下，且仅出于
        下列情况相关的目的，我们有可能需要较长时间保留“应用平台”和/或终端用户的个人信息：
      </p>
      <p>
        1、 遵守适用的法律法规等有关规定；
        <br />
        2、 遵守法院判决、裁定或其他法律程序的要求；
        <br />
        3、 遵守相关政府机关或其他有权机关的要求；
        <br />
        4、为执行相关服务协议或本政策、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的
        关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
      </p>
      <h3>（二）存储地域</h3>
      <p className="z-underline z-bold">
        我们会按照法律法规规定，将境内收集的个人信息存储于中国境内。
        目前，我们不会跨境传输或存储“应用平台”和/或终端用户的个人信息。将来如需跨境传输或存储的，
        我们会向“应用平台”和/或终端用户告知个人信息出境的目的、接收方、安全保证措施和安全风险，
        并征得“应用平台”的同意和/或终端用户的单独同意，并满足法律法规所规定的其他条件。
      </p>
      <h2>五、如何保护“应用平台”和/或终端用户的个人信息安全</h2>
      <h3>（一）安全保护措施</h3>
      <p className="z-underline z-bold">
        我们已使用符合业界标准的安全防护措施保护“应用平台”和/或终端用户提供的个人信息，防止数据
        遭到未经授权访问、公开披露、使用、修改、损毁、泄漏或丢失。
      </p>
      <p>
        我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、
        去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强将SDK集成到第三方上的安全
        能力。我们建立了专门保障个人信息安全的管理制度、流程和组织。我们也会审查该管理制度、
        流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。
      </p>
      <h3>（二）安全事件处置措施</h3>
      <p>
        若发生个人信息泄露、损毁、丢失等安全事件，我们会启动应急预案，阻止安全事件扩大。安全
        事件发生后，我们会及时以推送通知、邮件等形式向“应用平台”和/或终端用户告知安全事件的
        基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对“应用平台”和/或终端用户的应对建议
      </p>

      <h2>六、“应用平台”和/或终端用户如何管理其个人信息</h2>
      <h3>（一）针对“应用平台”</h3>
      <p>1、“应用平台”实现终端用户查阅、复制、修改、删除个人信息等权利的范围和方式将取决于“应用平台”功能设置。</p>
      <p>
        2、“应用平台”在使用本服务的过程中，如果终端用户根据“应用平台”与我们的约定提出了个人信息
        相关的行权请求，并且“应用平台”已确定该等行权请求涉及到了“应用平台”向本服务提供的个人信息时，
        请及时通过本指引第九条所述方式联系我们，并附上必要的书面证明材料。我们将及时核验相关材料，
        并按照相关法律法规，以及本指引等法律文本中明确的规则，为“应用平台”提供相应的支持与配合。
      </p>
      <p className="z-underline z-bold">
        3、如“应用平台”希望注销本服务，可以通过本指引第九条所述方式联系我们进行服务注销。
        我们将采取合理的步骤注销账户，我们将根据法律法规的要求删除个人信息。除非我们因法律原因
        而需要保存该等资料，我们将仅限于法律法规所规定的范围内进行处理，不会将其用于日常业务活动中。
      </p>
      <h3>（二）针对终端用户</h3>
      <p>由于终端用户不是我们的直接用户，我们建议：</p>
      <p className="z-bold">
        终端用户有权查阅、复制、修改、删除、撤回同意其相关的个人信息，如希望行使以上权利，
        请通过“应用平台”功能设置，也可以按照本政策第九条所述方式与我们联系。
        <br />
        当终端用户直接向我们主张个人信息主体权利时，应提供必要的证明文件，我们还会对终端用户
        进行身份验证。我们会向“应用平台”核实终端用户身份以保障终端用户账号安全的情况下，响应终端用户的相关请求；
        <br />
        当终端用户直接向我们主张个人信息主体权利时，根据“应用平台”的隐私政策或个人信息保护
        声明，我们还可能将与终端用户个人信息相关的请求直接发送给该“应用平台”，要求其处理和/或寻求帮助。
      </p>
      <h4>（1）查阅、复制和修改个人信息</h4>
      <p>
        当终端用户所使用的“应用平台”中某项功能由本服务产品和/或服务提供，终端用户可以在
        使用我们服务的过程中，向“应用平台”查阅、复制、修改其在使用“应用平台”产品中处理的
        个人信息，也可按照本政策第九条所述方式与我们联系。
      </p>
      <h4>（2）删除个人信息</h4>
      <p className="z-underline z-bold">
        符合下列情形之一的，“应用平台”或终端用户可以请求我们删除其有关个人信息：
        <br />
        a. 处理目的已实现、无法实现或者为实现处理目的不再必要；
        <br />
        b. 我们停止提供服务，或者信息保存期限已届满；
        <br />
        c. 终端用户撤回同意；
        <br />
        d. 认为我们的处理违反法律、行政法规或者违反约定处理个人信息；
        <br />
        e. 法律、行政法规规定的其他情形。
        我们将会根据“应用平台”或终端用户的删除请求进行评估，若满足相应规定，我们将会采取
        包括技术手段在内的相应步骤进行处理。
      </p>
      <h4>（3）撤回同意</h4>
      <p className="z-bold">
        基于终端用户的同意而进行的个人信息处理活动，终端用户有权撤回该同意。由于我们与
        终端用户无直接的交互对话界面，终端用户可以直接向“应用平台”主张撤回同意的权利，
        也可通过“应用平台”提供的平台功能设置主张撤回同意的权利。如“应用平台”需要我们
        协助终端用户的权利请求，可按照本指引第九条所述方式向我们提出协助请求。
      </p>
      <p>
        当终端用户撤回个人信息处理的同意并关闭至信链服务时，我们无法继续为终端用户提供
        撤回同意所对应的功能和服务，也不再处理终端用户相应的个人信息。但终端用户撤回同意
        的决定，不会影响撤回前基于终端用户同意已进行的个人信息处理活动的效力。
      </p>
      <h4>（4）注销服务</h4>
      <p className="z-bold">
        如终端用户希望注销具体产品或服务，且具体产品或服务的隐私政策中有明确指明，
        终端用户可通过该方式申请注销相应服务。
      </p>
      <h3>（三）响应“应用平台”和/或终端用户的合理请求</h3>
      <p className="z-bold">
        我们会响应“应用平台”和/或终端用户上述的合理请求，如无端重复、需要过多技术手段
        （例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际
        （例如，涉及备份磁盘上存放的信息）的请求，我们可能会予以拒绝。
      </p>
      <p>
        在以下情形中，我们将无法响应“应用平台”和/或终端用户的请求：
        <br />
        a. 与我们履行法律法规规定的义务相关的；
        <br />
        b. 与国家安全、国防安全直接相关的；
        <br />
        c. 与公共安公共卫生、重大公共利益直接相关的；
        <br />
        d. 与犯罪侦查、起诉、审判和执行判决等直接相关的；
        <br />
        e. 我们有充分证据表明“应用平台”和/或终端用户存在主观恶意或滥用权利的；
        <br />
        f. 出于维护“应用平台”和/或终端用户或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />
        g. 响应“应用平台”和/或终端用户的请求将导致“应用平台”或其他个人、组织的合法权益受到严重损害的；
        <br />
        h. 涉及商业秘密的。
        <br />
        如“应用平台”和/或终端用户的对上述权利实现存在疑问，可以根据本政策第九条所述方式与我们联系。
      </p>
      <h3>（一）针对“应用平台”</h3>
      <h2>七、如何处理儿童个人信息</h2>
      <p className="z-underline z-bold">
        1、请“应用平台”确保是18周岁（含）以上人士。请“应用平台”理解并知悉，如果服务是针对儿童用户的，
        请“应用平台”务必确保终端用户（儿童）的监护人已经阅读并同意了相关隐私政策以及
        <a href="https://privacy.tencent.com/privacy-children.htm" target="_blank" rel="noreferrer">
          《腾讯儿童隐私保护声明》
        </a>
        ，并且经其同意后提供儿童个人信息给我们。
      </p>
      <p className="z-underline z-bold">
        2、如果我们在不知情的情况下收集了儿童的个人信息，我们将及时对其进行删除，除非法律法规要求我们保留此类资料。
        如果“应用平台”和/或终端用户认为我们错误或意外地向儿童收集了信息，请按本政策第九条所述方式及时联系我们。
        如果经我们发现“应用平台”未获得儿童的监护人同意向我们提供儿童个人信息的，
        我们将尽快删除该儿童的个人信息并确保不对其进一步处理。
      </p>
      <h2>八、如何变更与更新本政策</h2>
      <p>
        1、 为给“应用平台”提供更好的服务以及随着本平台产品和/或服务的不断发展与变化，我们可能会适时对本政策进行修订。
      </p>
      <p>
        2、当本政策的条款发生变更时，我们会在版本更新时以弹窗、红点提示、网站公告等方式进行提示，并说明生效日期。
        此外，如果更新后的本政策对终端用户的权利有所影响，还需要请“应用平台”适时更新其隐私政策。
      </p>
      <p>
        3、对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件或站内信形式或
        公告形式发送通知，说明本政策的具体变更内容）。因此，请随时查看和了解本政策内容。如果“应用平台”
        和/或终端用户不同意接受本政策，请停止接入和使用我们的服务。
        <br />
        本政策所指的重大变更包括但不限于：
        （1）我们的服务模式发生重大变化。如处理个人信息的目的、类型、个人信息的使用方式等；
        （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产、并购等引起的所有者变更等；
        （3）个人信息共享、转让或公开披露的主要对象发生变化； （4）个人信息处理方面的权利及其行使方式发生重大变化；
        （5）负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化； （6）个人信息安全影响评估报告表明存在高风险。
      </p>
      <h2>九、如何联系我们</h2>
      <p className="z-underline z-bold">
        如您对本用户协议有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，
        请通过至信链官网上（https://zxchain.qq.com）的与我们联系，我们将尽快审核问题，
        并在验证用户身份后十五个工作日内容予以回复。
      </p>
      <h2>十、其它</h2>
      <p>
        1、{' '}
        <a href="https://privacy.tencent.com/policy/tencent-privacypolicy" target="_blank" rel="noreferrer">
          《腾讯隐私政策》
        </a>
        是腾讯统一适用的一般性隐私条款，其中所规定的用户权利及信息安全保障措施均适用于“应用平台”和/或终端用户。如
        <a href="https://privacy.tencent.com/policy/tencent-privacypolicy" target="_blank" rel="noreferrer">
          《腾讯隐私政策》
        </a>
        与本政策存在不一致或矛盾之处，请以本政策为准。
      </p>
      <p>
        2、我们请“应用平台”特别注意：当“应用平台”进行个人信息的处理活动，如果符合以下任何一项条件，
        请“应用平台”注意同时遵守《欧盟通用数据保护条例》（GeneralDataProtectionRegulation，以下简称“GDPR”）：
        <br />
        （1）“应用平台”在欧盟经济区域（“EEA”），无论该处理活动是否在欧盟进行；
        <br />
        （2）向在EEA的个人提供商品或服务（不论是否有偿），或者监视其在EEA内的行为；
        不在EEA内，但根据国际公法适用欧盟成员国法律（例如EEA成员国的使馆或领事馆）。
        <br />
        （3）如果“应用平台”和/或终端用户来自EEA，我们收集和使用上述个人信息的法律
        依据将取决于相关个人信息以及我们收集信息的具体背景。
        <br />
        （4）对于EEA中的“应用平台”和/或终端用户，有权根据适用的数据保护法律向主管数据
        保护机构投诉或向具有管辖权的法院提起诉讼。
        <br />
      </p>
      <p>
        3、如果“应用平台”和/或终端用户对我们收集和使用个人信息的法律依据有疑问或需要
        进一步信息的，请根据本政策第九条所述方式与我们联系。
      </p>
    </div>
  );
}
