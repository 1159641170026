import React, { Fragment, useState, useEffect } from 'react';
import Section from '../../components/Section';
import { Row, Col } from '../../components/Grid';
import Media from '../../components/Media';
import {
  Header,
  Footer,
  CarouselCustomer,
  CarouseBanner,
  MediaAdvantages,
  MediaRecommend,
} from '../../containers/index';
import {
  ExcellentData,
  RecommendData,
  CustomerData,
  CredibilityData,
  ScenesData,
  CarouseBannerData,
  CompanionData,
  TechSupportData,
} from '../../mock/home.js';
import { numToFormat } from '../../common/utils';
import request from '../../common/request';

import './index.scss';
import scenesImg from './../../mock/img/home/scenes.png';
function Home() {
  const [scenes, setScenes] = useState(0);
  const handelScenes = (value) => {
    setScenes(value);
  };
  const getStatisticsList = (data) => {
    const { totalTx, totalAddr, height, blockNum } = data;
    return [
      { title: '累计交易量', number: totalTx, desc: '已上链的总交易笔数' },
      { title: '地址数', number: totalAddr, desc: '链上地址总数' },
      { title: '区块高度', number: height, desc: '链上最新的区块的序号' },
      {
        title: '交易速度',
        number: blockNum,
        desc: '每分钟处理的交易笔数',
      },
    ];
  };
  const [statisticsList, setStatisticsList] = useState(
    getStatisticsList({
      totalTx: '-',
      totalAddr: '-',
      height: '-',
      blockNum: '-',
    }),
  );
  useEffect(() => {
    request({
      url: '/api/v1/index',
    }).then((response) => {
      const { data } = response.data;
      setStatisticsList(
        getStatisticsList({
          totalTx: numToFormat(data.totalTx),
          totalAddr: numToFormat(data.totalAddr),
          height: data.height,
          blockNum: numToFormat(data.blockNum),
        }),
      );
    });
  }, []);
  return (
    <Fragment>
      <Header />
      <CarouseBanner data={CarouseBannerData} navigation={false} showPagination={true} />
      <section className="home-page-statistics">
        <div className="home-page-statistics__inner">
          <div className="home-page-statistics__desc show-moblie">
            <p className="home-page-statistics__title">{CarouseBannerData[0].title}</p>
            <p className="home-page-statistics__title">{CarouseBannerData[0].des}</p>
            <p className="home-page-statistics__info">
              「至信链」开放联盟链是合规、开放、易用的底层区块链平台，企业用户与个人开发者既可以快速调用已封装好的链服务，也可以自行编写智能合约并部署，用以服务各类客户。
            </p>
          </div>
          <div className="home-page-statistics__body">
            <Row gutter={[0, 0]} overflow>
              {statisticsList.map((item, index) => (
                <Col phone={12} colSpan={6} key={`col-${index}`}>
                  <div className="home-page-statistics__media">
                    <div className="media-title">{item.title}</div>
                    <div className="media-number">{item.number}</div>
                    <div className="media-desc">{item.desc}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </section>
      <Section title={'我们的优势'}>
        <MediaAdvantages data={ExcellentData} />
      </Section>
      <Section title={'产品架构'} className="bg-gray">
        <img className="home-img" src={scenesImg} alt="产品架构" />
      </Section>
      <Section title={'推荐服务'}>
        <MediaRecommend data={RecommendData} />
      </Section>
      <Section title={'应用场景'} className="home-page-scenes">
        <div className="home-page-scenes__body">
          {ScenesData.map((item, index) => (
            <div className="home-page-scenes__item" key={`item-${index}`}>
              <div
                onClick={() => handelScenes(index)}
                className={`home-page-scenes__media ${index === scenes ? 'is-active' : ''}`}
              >
                <div className="media-icon" style={{ backgroundImage: `url(${item.icon})` }}></div>
                <div className="media-title">{item.title}</div>
                <div className="home-page-scenes__synopsis">
                  <div className="synopsis-title">{item.title}</div>
                  <div className="synopsis-desc">{item.desc}</div>
                  <ol className="synopsis-lable">
                    {item.lable.map((items, idx) => (
                      <li key={`items-${idx}`}>{items}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <Section title={'客户和案例'} className="bg-gray hidden">
        <CarouselCustomer data={CustomerData} />
        <div className="home-page-companion">
          {CompanionData.map((item, index) => (
            <img key={index} src={item} />
          ))}
        </div>
      </Section>
      <Section title={'公信力节点'}>
        <Row gutter={[24, 24]}>
          {CredibilityData.map((item, index) => (
            <Col colSpan={6} phone={12} key={`col-${index}`}>
              <Media data={item} imgCard imgRadius imgBgWhite />
            </Col>
          ))}
        </Row>
      </Section>
      <Section title={'技术支持'}>
        <Row gutter={[24, 24]}>
          {TechSupportData.map((item, index) => (
            <Col colSpan={8} phone={8} key={`col-${index}`}>
              <Media data={item} imgRadius imgBgTrans hover />
            </Col>
          ))}
        </Row>
      </Section>
      <Footer />
    </Fragment>
  );
}

export default Home;
