import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Home, About, DocumentCenter, Privacy, Service, Indetity } from './pages';

import './styles/index.scss';

export default function App() {
  return (
    <Switch>
      <Route path="/document-center" component={DocumentCenter} />
      <Route path="/about" component={About} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/service" component={Service} />
      <Route path="/identity" component={Indetity} />
      <Route path="/" component={Home} />
    </Switch>
  );
}
