import manual1 from './img/DocumentCenter/至信链@2x.svg';
import manual2 from './img/DocumentCenter/应用服务@2x.svg';
export const RecommendData = [
  {
    title: '至信链',
    desc: '至信链开放联盟链',
    img: manual1,
  },
  {
    title: '应用服务',
    desc: ' ',
    img: manual2,
  },
];
