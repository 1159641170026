import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mPrefix } from '../../utility';
import PropTypes from 'prop-types';
import './index.scss';
SwiperCore.use([EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default function CarouselRecord(props) {
  const {
    data = [],
    spaceBetween = 0,
    slidesPerView = 1,
    showPagination = false,
    autoplay = false,
    loop = true,
    navigation = true,
  } = props;
  // 时间按钮
  const [buttonValue, setButtonValue] = useState(null);
  // 获取第一个开始的时间
  const dateTime = data[0].date;
  // 移动端显示一屏多大
  const [showScreen, setShowScreen] = useState(slidesPerView);
  // 是否可以拖动滚动
  const [drag, setDrag] = useState(false);
  const wi = () => {
    if (window.innerWidth < 768) {
      setShowScreen(1);
      setDrag(true);
    } else {
      setDrag(false);
    }
  };
  useEffect(() => {
    wi();
    window.onresize = () => {
      wi();
    };
    return () => {
      window.onresize = null;
    };
  }, []);
  return (
    <div className={`${mPrefix}-carouselRecord`}>
      <Swiper
        spaceBetween={spaceBetween}
        // effect="fade"
        pagination={
          showPagination && {
            el: `.${mPrefix}-carouselRecord__pagination,.${mPrefix}-carouselRecord__i`,
            bulletClass: `${mPrefix}-carouselRecord__pagination-item`,
            bulletActiveClass: 'is-actived',
            clickable: true,
            type: 'custom',
            renderCustom(swiper, current, total) {
              let html = '';
              for (let i = 1; i <= total; i++) {
                html += `<div class="${mPrefix}-carouselRecord__pagination-item ${current === i ? 'is-actived' : ''}">${
                  data[i - 1].date
                }</div>`;
              }
              setButtonValue(data[current - 1].date);
              return html;
            },
          }
        }
        slidesPerView={showScreen}
        loop={loop && data.length > slidesPerView}
        mousewheel={true}
        autoplay={
          autoplay
          && data.length > slidesPerView 
          && {
            observer: true,
            autoplayDisableOnInteraction: false,
            observeParents: true,
            stopOnLastSlide: false,
            delay: 3000,
            speed: 3000,
            disableOnInteraction: false,
          }
        }
        navigation={
          navigation && {
            disabledClass: 'disabled',
            nextEl: `.${mPrefix}-carouselRecord-swiper-button-next`,
            prevEl: `.${mPrefix}-carouselRecord-swiper-button-prev`,
          }
        }
      >
        {data.map((item, index) => (
          <SwiperSlide className={`${drag ? '' : 'swiper-no-swiping'}`} key={`swiper-${index}`}>
            <div className="swiper-box-item">
              <div className={`${mPrefix}-setp--warp`}>
                <div className={`${mPrefix}-setp--title`}>{item.date}</div>
                <div className={`${mPrefix}-setp`}>
                  <div className={`${mPrefix}-setp__inner`}>
                    {item.list.map((subitem, index) => (
                      <div className={`${mPrefix}-setp__item`} key={`${mPrefix}-setp__item-${index}`}>
                        <div className={`${mPrefix}-setp__item-point`}></div>
                        <div className={`${mPrefix}-setp__item-num`}>{subitem.moon}</div>
                        <div className={`${mPrefix}-setp__item-content`}>
                          <div className={`${mPrefix}-setp__item-content--des`}>{subitem.content}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {data.length > slidesPerView && <div className={`${mPrefix}-carouselRecord__pagination`}></div>}
      {data.length > slidesPerView && (
        <div className="carouselRecord-swiper-button">
          <div className={`${mPrefix}-carouselRecord-swiper-button-prev`}>
            {Number(dateTime) < Number(buttonValue) && Number(buttonValue) - 1}
          </div>

          <div className={`${mPrefix}-carouselRecord-swiper-button-next`}>
            {Number(dateTime) + Number(data.length) - 1 > Number(buttonValue) && Number(buttonValue) + 1}
          </div>
        </div>
      )}
    </div>
  );
}

CarouselRecord.propTypes = {
  data: PropTypes.array,
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  showPagination: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  navigation: PropTypes.bool,
};
