import React from 'react';
import Button from '../../components/Button';
import { mPrefix } from '../../utility/js/Prefix';
import code from './../../mock/img/wechat.jpg';

import './index.scss';

function Footer() {
  const goTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const contactPage = () => {
    window.open('https://cloud.tencent.com/online-service?source=PRESALE&from=service-support');
  };

  return (
    <section className={`${mPrefix}-footer`}>
      <div className={`${mPrefix}-footer__inner`}>
        <div className={`${mPrefix}-footer__left`}>
          <div className={`${mPrefix}-footer__title`}>我要咨询</div>
          <div className={`${mPrefix}-footer__desc`}>
            想更多了解至信链，请使用在线咨询服务，我们将结合您的业务为您做详细介绍及服务
          </div>
          <div className={`${mPrefix}-footer__btn`}>
            <Button primary onClick={() => contactPage()}>
              立即咨询
            </Button>
          </div>
        </div>
        <div className={`${mPrefix}-footer__right`}>
          <div className={`${mPrefix}-footer__title`}>关注或联系我们</div>
          <div className={`${mPrefix}-footer__desc`}>添加至信链小助手，获取最新资讯</div>
          <div className={`${mPrefix}-footer__vcode`}>
            <img src={code} alt="联系我们" />
          </div>
          <div className={`${mPrefix}-footer__desc`}>售前咨询热线</div>
          <a href="tel:4009100100" className={`${mPrefix}-footer__telephone`}>
            <span>4009100100 转 1</span>
          </a>
        </div>
        <div className={`${mPrefix}-footer__copyright`}>
          <p>Copyright @ All Rights Reserved. 至信链 版权所有：腾讯</p>
          <p>
            粤公网安备 44030002000001号 ｜ 粤网信备 44030521699273100036号 ｜
            增值电信业务经营许可证：粤B2-20090059 B2-20090028{' '}
            <a
              href="https://console.zxchain.qq.com/docs?docpath=doc/content/%E7%9B%B8%E5%85%B3%E5%8D%8F%E8%AE%AE/%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html"
              target="_blank"
              rel="noreferrer"
            >
              隐私政策
            </a>
          </p>
        </div>
        <div className={`${mPrefix}-footer__sidebar`}>
          <a
            href="https://cloud.tencent.com/online-service?source=PRESALE&from=service-support"
            className="contact-btn"
          >
            联系我们
          </a>
          <div className="gotop-btn" onClick={goTop}></div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
