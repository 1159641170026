import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { Prefix } from '../../utility';
import './index.scss';
export default function Media(props) {
  const {
    className,
    data,
    imgCard,
    imgRadius,
    imgBgWhite,
    imgBgTrans,
    showBigImg,
    horizon,
    radius,
    card,
    shadow,
    hover,
    vertical,
    opposite,
    btns,
  } = props;
  const cls = classNames(
    className,
    `${Prefix}-media`,
    card ? `${Prefix}-media--card` : '',
    radius ? `${Prefix}-media--radius` : '',
    shadow ? `${Prefix}-media--shadow` : '',
    data.toUrl && hover ? `${Prefix}-media--hover` : '',
    horizon ? `${Prefix}-media--horizon` : '',
    vertical ? `${Prefix}-media--vertical` : '',
    opposite ? `${Prefix}-media--opposite` : '',
  );
  const toUrl = () => {
    data.toUrl && window.open(data.toUrl);
    showBigImg?.(data.img);
  };
  return (
    <div className={cls} onClick={toUrl}>
      {data.img && (
        <div
          className={`${Prefix}-media__img  ${imgCard ? 'is-card' : ''} ${imgRadius ? 'is-radius' : ''} ${
            imgBgWhite ? 'bg-white' : ''
          } ${imgBgTrans ? 'bg-trans' : ''} ${data.doubleImg ? 'double' : ''}`}
        >
          <img src={data.img} alt={data.title} />
        </div>
      )}
      <div className={`${Prefix}-media__info`}>
        {data.title && <div className={`${Prefix}-media__info-title`}>{data.title}</div>}
        {data.subTitle && <div className={`${Prefix}-media__info-subTitle`}>{data.subTitle}</div>}
        {data.desc && (
          <div className={`${Prefix}-media__info-desc`}>
            <span>{data.desc}</span>
          </div>
        )}
        {btns && <div className={`${Prefix}-media__info-btns`}>{btns}</div>}
        {data.link && (
          <div className={`${Prefix}-media__info-link`}>
            <Link to="/" type="arrow" target="_blank" href={data.href}>
              {data.link}
            </Link>
          </div>
        )}
        {data.source && (
          <div className={`${Prefix}-media__info-source`}>
            <i className={`${Prefix}-media__info-source--line`}></i>
            {data.source}
          </div>
        )}
      </div>
    </div>
  );
}
Media.propTypes = {
  /**
   * 描述
   */
  className: PropTypes.string,
  /**
   * 描述
   */
  data: PropTypes.object,
  card: PropTypes.bool,
  radius: PropTypes.bool,
  shadow: PropTypes.bool,
  horizon: PropTypes.bool,
  imgCard: PropTypes.bool,
  imgBgWhite: PropTypes.bool,
  imgBgTrans: PropTypes.bool,
  imgRadius: PropTypes.bool,
  hover: PropTypes.bool,
  vertical: PropTypes.bool,
  opposite: PropTypes.bool,
  btns: PropTypes.string,
  showBigImg: PropTypes.func,
};
