import React, { useContext } from 'react';
import classNames from 'classnames';
import { Prefix, PublicContent } from '../../utility';
import PropTypes from 'prop-types';
import './index.scss';

export function Col(props) {
  const gutter = useContext(PublicContent);
  // const rp = useContext(PublicContent);
  const {
    className, // 自定义类名
    style, // 自定义样式
    width, // 宽度
    pull, // 左间隔 以栅格数为单位
    push, // 右间隔 以删格数为单位
    colSpan, // 宽度，最大值为24，栅格为24栅格
    phone, // 移动端生效
    ...rest // 自定义其他属性
  } = props;
  const cls = classNames(
    `${Prefix}-col`,
    {
      [`${Prefix}-col-${colSpan}`]: colSpan,
      [`${Prefix}-col--push-${push}`]: push,
      [`${Prefix}-col--pull-${pull}`]: pull,
      [`${Prefix}-col--phone-${phone}`]: phone,
    },
    className,
  );
  const styles = {
    ...style,
    width: `${width}px`,
  };

  if (gutter) {
    const x = gutter[0] || 0;
    const y = gutter[1] || 0;
    styles.padding = `${y / 2}px ${x / 2}px`;
  }

  return (
    <div className={cls} style={styles} {...rest}>
      {props.children}
    </div>
  );
}

Col.propTypes = {
  className: PropTypes.string, // 自定义类名
  style: PropTypes.object, // 自定义样式
  width: PropTypes.number, // 宽度
  pull: PropTypes.number, // 左间隔 以栅格数为单位
  push: PropTypes.number, // 右间隔 以删格数为单位
  colSpan: PropTypes.number, // 宽度，最大值为24，栅格为24栅格
  phone: PropTypes.number, // 移动端生效
  children: PropTypes.element,
};
