import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Prefix, mPrefix } from '../../utility';
import Media from '../../components/Media';
import PropTypes from 'prop-types';
import './index.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default function CarouselHonor(props) {
  const {
    data = [],
    spaceBetween = 0,
    slidesPerView = 1,
    showPagination = false,
    autoplay = false,
    loop = true,
    navigation = true,
  } = props;
  // 移动端显示一屏多大
  const [showScreen, setShowScreen] = useState(slidesPerView);
  // 是否可以拖动滚动
  const [drag, setDrag] = useState(false);
  const wi = () => {
    if (window.innerWidth < 768) {
      setShowScreen(1.2);
      setDrag(true);
    } else {
      setDrag(false);
    }
  };
  useEffect(() => {
    wi();
    window.onresize = () => {
      wi();
    };
    return () => {
      window.onresize = null;
    };
  }, []);
  const [bigImg, setBigImg] = useState(false);
  const showBigImg = (data) => {
    setBigImg(data);
  };
  // 关闭显示大图
  const closeBigImg = () => {
    setBigImg(false);
  };
  return (
    <div className={`${mPrefix}-carouselHonor`}>
      <Swiper
        spaceBetween={spaceBetween}
        pagination={showPagination && data.length > 1 && { clickable: true }}
        navigation={
          navigation && {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'disabled',
          }
        }
        slidesPerView={showScreen}
        autoplay={
          autoplay
          && data.length > slidesPerView 
          && {
            delay: 3000,
            speed: 3000,
            observer: true,
            observeParents: true,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            autoplayDisableOnInteraction: false,
          }
        }
        loop={loop && data.length > slidesPerView}
        mousewheel={true}
      >
        {data.map((item, index) => (
          <SwiperSlide className={`${drag ? '' : 'swiper-no-swiping'}`} key={`swiper-${index}`}>
            <div className="swiper-box-item">
              <Media data={item} radius card shadow hover imgCard showBigImg={showBigImg} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {data.length > slidesPerView && (
        <div className={`${mPrefix}-swiper-button`}>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      )}
      {bigImg && (
        <div className={`${Prefix}-imgDialog`}>
          <div className={`${Prefix}-imgDialog__bg`} onClick={closeBigImg}></div>
          <div className={`${Prefix}-imgDialog__close`} onClick={closeBigImg}></div>
          <div className={`${Prefix}-imgDialog__inner`}>
            <img className="img" src={bigImg} alt={data.title} />
          </div>
        </div>
      )}
    </div>
  );
}

CarouselHonor.propTypes = {
  data: PropTypes.array,
  spaceBetween: PropTypes.number,
  slidesPerView: PropTypes.number,
  showPagination: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  navigation: PropTypes.bool,
};
