import React from 'react';

export default function Privacy() {
  return (
    <div className="z-doc">
      <h1 className="z-center">至信链平台隐私政策</h1>
      <p>本政策最近更新于：2021年12月09日。</p>
      <p>
        感谢您选择和使用至信链平台服务！为方便您注册、登录、使用相关服务，
        以及为您提供更加个性化的用户体验和服务，您在使用至信链平台服务时，
        我们可能会收集和使用您的相关信息。至信链平台非常重视用户信息的保护，
        我们将按照法律法规要求，采取相应的安全保护措施。我们希望通过本《至信链平台隐私政策》
        （以下简称“政策”）向您说明，在您使用至信链平台提供的产品、服务时，
        我们如何收集、使用、存储和共享您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方法。
      </p>
      <p className="z-underline">
        <b>
          本政策与您所使用的至信链平台服务息息相关，希望您仔细阅读。
          您以在线勾选等任何方式确认接受本政策或使用至信链平台服务的，
          即视为您已充分阅读、理解并同意接受本政策的约束，本政策即在您与至信链平台之间产生法律效力。
        </b>
      </p>
      <h2>1. 本政策的适用范围</h2>
      <h3> 1.1. 【公司信息】</h3>
      <p>
        <b className="z-underline">本政策适用于【腾讯云计算（北京）有限责任公司】至信链平台</b>
        （以下简称“腾讯云”或“我们”）通过至信链平台官网以及至信链其他平台提供的产品和服务，本政策另有约定除外。
      </p>
      <h3> 1.2. 【服务范围】</h3>
      <p>
        在您使用、获取或购买至信链平台提供的任何产品或服务并且 (1)
        在您将您的个人信息提供给我们，并按照我们的处理目的而使用的情况下，
        我们将根据《至信链平台隐私政策》收集并处理您的个人信息；或(2) 在您委
        托我们处理您的个人信息的情况下，我们将根据《至信链平台隐私政策》处理您的个人信息。
      </p>
      <h3> 1.3. 【第三方提供的服务】</h3>
      <p>
        <b className="z-underline">
          本政策不适用于其他第三方向您提供的产品和服务，例如至信链平台上的第三
          方服务商向您提供产品或服务时，需由您与服务商另行确定有关信息、数据等的使用政策、服务协议等。
        </b>
      </p>
      <h3> 1.4. 【用户信息】</h3>
      <p>
        <b className="z-underline">
          本政策适用于您注册或使用至信链平台所提交的所有信息、资料等，
          以及您使用至信链平台服务时提交的或至信链平台为了向您提供服务
          而收集的信息（客户数据除外），前述信息统称为“用户信息”。例如，名称、营业执照、身份证号码、联系方式等。
        </b>
      </p>
      <h3> 1.5. 【客户数据】</h3>
      <p>
        <b>
          客户数据是指您使用至信链平台服务（包括通用API、SDK等）过程中
          以上传、传输、存储，或者以分析、分发等任何方式处理的各类数据，
          包括但不限于您在日常经营活动中所收集、产生的用户的个人信息等。
        </b>
      </p>
      <p>
        <b>
          （1）您应确保客户数据是依法收集、使用（包括但不限于委托处理等）的，
          您不会也不曾以任何方式侵犯任何个人或实体的合法权利。{' '}
        </b>
      </p>
      <p>
        <b>
          （2）您保证有权使用至信链平台服务对客户数据进行存储、传输、
          分析和分发等任何处理，我们将按照您的指令、委托处理您的客户数据。{' '}
        </b>
      </p>
      <p>
        <b>
          （3）您理解并同意，中国及其他国家、地区可能对数据存储、出境等有相关的规定，
          在您使用至信链平台服务对客户数据进行存储、传输等操作前，您应当遵照相关地区
          适用的法律法规进行充分、必要的评估、审批，确保符合相关规定。{' '}
        </b>
      </p>
      <p>
        <b>（4）您可以通过《至信链平台服务协议》知悉更多关于客户数据的相关政策。</b>
      </p>
      <h3> 1.6. 【协议变动】</h3>
      <p>
        {' '}
        <b>
          至信链平台有权根据需要不定时地制定、修改本协议或各类规则，
          如本政策及规则有任何变更，一切变更以本平台最新公布的内容为准。
        </b>
        用户有权拒绝接受新的政策和规则，但必须停止使用至信链平台提供的各项服务。
      </p>
      <h2>2. 我们收集的信息</h2>
      <h3> 2.1. 【我们收集信息的方式】</h3>
      <p>
        <b>我们可能通过以下几种方式收集您的信息： </b>
      </p>
      <p>
        <b>（1）您主动提交，例如您或您授权的主体在注册账号、完善信息等情况下填写、上传的相应信息。 </b>
      </p>
      <p>
        <b>
          （2）您授权他方或您的服务商代为提交、记录的信息，
          例如您委托特定服务商在至信链平台系统中填写、上传的您的相应信息。{' '}
        </b>
      </p>
      <p>
        <b>
          （3）我们在您使用我们的服务过程中以主动收集或产生相应的记录等方式收集的信息，
          例如在您登录至信链平台账号时后台记录下的登录时间，您使用特定至信链平台服务的操作记录等。
        </b>
      </p>
      <h3> 2.2. 【身份信息的内容】</h3>
      <p>
        <b>为完成至信链平台账号的注册，您需要根据您选择的注册方式提交真实、合法、有效的信息，设置、确认登录密码等</b>
        ，包括但不限于微信号码、QQ
        号码、电子邮箱、微信公众号、用户名、姓名/名称、联系人、联系电话、联系地址、银行账户信息、工商登记信息等。
      </p>
      <h3> 2.3. 【第三方平台账号授权】 </h3>
      <p>
        如果您使用其他平台的账号登录至信链平台或将其他平台的账号与至信链平台账号进行关联、绑定，
        <b>您将授权至信链平台获得您其他平台账号的相关信息</b>，例如微信号码、QQ
        号码、邮箱地址、微信公众号、手机号码、昵称、头像、性别等。 若
        <b>
          您通过其他平台登录或者以账号关联等方式使用至信链平台服务，且该类您所使用的服务要求进行实名认证的，
          您需要在至信链平台另行进行实名认证或者将原有的实名认证信息同步授权给至信链平台，以便顺利使用该类服务。
        </b>
      </p>
      <h3> 2.4. 【实名认证的信息】</h3>
      <p>
        在您进行实名认证时，您需要根据您所选择的认证类型提供对应的信息，
        例如姓名、身份证、名称、营业执照、对公银行账号、通讯地址、行业信息、手机号、人脸信息、联系方式等。{' '}
        <b>
          实名认证是判断账号归属的重要依据，为了您的账号安全，请务必审慎选择认证方式，提交合法、真实、有效的信息。
          部分服务、活动仅支持实名认证用户使用或参与，若您未认证，将无法使用或参与前述服务和活动。
        </b>
      </p>
      <h3> 2.5. 【注册账号妥善保管】</h3>
      <p>
        注册和管理账号涉及的信息与您管理、使用至信链平台账号、至信链平台服务等紧密相关（例如验证您的身份等），
        请您务必谨慎确认和维护；前述信息发生变化时，请及时进行更改，例如发生管理人员变动时，
        应提前对账号密码、联系人、联系电话等进行变更。
      </p>
      <h3> 2.6. 【服务中产生的信息】</h3>
      <p>
        <b>为提供持续服务和保证服务质量所需，在您使用至信链平台服务期间，我们将记录并保存您登录和使用服务的相关信息</b>
        ，包括但不限于：
      </p>
      <p>
        （1） 操作记录，我们会接收并记录您使用至信链平台服务的操作记录及相关信息，例如身份
        ID、操作时间、操作的对象信息（资源 ID、区域、所属产品等）、IP
        地址、浏览器的类型、使用的语言、设备型号、操作系统版本及网页浏览/检索记录等。
      </p>
      <p>
        （2）合同信息，如果您需要申请线下交付或进行产品测试等，为履行合同所必需，
        您应提供联系人、联系方式、地址、开票信息、订单等必要信息。
      </p>
      <p>（3）其他信息，例如工单记录、咨询沟通记录等。</p>
      <h2>3. 我们如何使用 Cookie 及相关技术</h2>
      <h3> 3.1. 【使用方式】</h3>
      <p>
        <b>我们可能会通过 Cookie 和其他相关技术收集和使用您的信息。我们使用 Cookie 的具体用途包括： </b>
      </p>
      <p>
        <b>（1） 记住您的身份。例如：Cookie 有助于我们辨认您作为我们的注册用户的身份。 </b>
      </p>
      <p>
        <b>（2） 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括但不限定制化页面、推荐等。</b>
      </p>
      <h3> 3.2. 【Cookie服务政策】</h3>
      <p>
        （1）您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用
        Cookie，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。
      </p>
      <p>（2）通过至信链平台 Cookie 记录的有关信息，将适用本政策。</p>
      <h2>4. 我们如何使用收集的信息</h2>
      <p>为了向您提供优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途： </p>
      <p>
        <b>（1）向您提供服务。</b>
      </p>
      <p>
        （2）<b>满足您的个性化需求。</b>
        例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。
      </p>
      <p>
        （3）<b>服务优化和开发。</b>
        例如，我们会根据至信链平台系统响应您的需求时所产生的信息，优化我们的服务。
        例如，为履行本政策或您使用的其他相关产品、服务的服务协议、规则等的约定。
      </p>
      <p>
        （4） <b>保护至信链平台、至信链平台用户和至信链平台的合作伙伴。</b>
        例如，我们会将您的信息用于身份验证、安全防范、诈骗监测，以及判断您的账号安全等用途。
        例如，您在使用安全功能或其他类似服务时，我们会对恶意程序或病毒进行检测，或为您识别诈骗信息。
        例如，在处理相关纠纷、投诉时，为相关当事人提供有关信息以便更好的促进纠纷、投诉的处理、解决等。
      </p>
      <p>
        （5）<b>向您推送相关业务消息（如财务消息、产品消息、至信链平台动态等）和商业广告。</b>
        例如，至信链平台可以通过您的手机号码、电子邮箱等联系方式以短信、电话、电子邮件
        等任何方式向您推送商业广告，宣传、推广至信链平台服务或至信链平台上的其他服务。
      </p>
      <p>
        （6） <b>向您提供与您更加相关的服务。</b>
        例如，基于您的信息统计、分析情况或者至信链平台服务的其他用户数据统计、
        分析情况，向您提供您可能感兴趣的类似功能或服务等。
      </p>
      <p>
        （7） <b>邀请您参与有关我们产品和服务的调查。</b>
      </p>
      <p>
        （8）{' '}
        <b>
          我们可能将至信链平台用户使用某项或多项至信链平台服务的数据单独或与其他服务的用户
          使用数据进行整合、分析等，形成统计类用户报告、商业分析等资料（统称“统计资料”），
          在至信链平台内部或外部共享、发布、展示等，我们会确保统计资料不会涉及可以识别特定用户身份的信息。
        </b>
      </p>
      <p>
        （9）{' '}
        <b>
          其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的
          关联性，我们会在使用信息前重新征得您的同意。
        </b>
      </p>
      <h2>5. 我们共享、转移的信息</h2>
      <h3> 5.1. 【共享信息】</h3>
      <p>
        出于以下目的或场景，为了更好的向您提供服务，我们需要向第三方合作伙伴
        共享你的部分数据，并要求其严格遵守我们关于数据隐私保护的措施与要求，
        包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行处理，避免识别出个人身份，保障隐私安全：
      </p>
      <p>
        <b className="z-underline">
          （1）按照本文第2条“我们收集的信息”中的相应功能或服务所必需，
          以及出于第4条“我们如何使用收集的信息”中的部分所述目的所必需。{' '}
        </b>
      </p>
      <p>
        <b className="z-underline">（2）履行我们在《至信链平台用户服务协议》或本政策中的义务、行使我们的权利。 </b>
      </p>
      <p>
        <b className="z-underline">
          （3）为提供更好的服务，我们可能委托第三方服务提供商、服务商及代理（统称“合作伙伴”）向您提供服务
        </b>
        ，包括但不限于咨询顾问服务、客户服务、产品推荐、技术服务等，因此，为向您提供服务所必需，
        我们会与合作伙伴共享您的某些信息。例如，为完成身份验证，为向您提供更加合适的产品、服务等。
      </p>
      <p>
        <b className="z-underline">
          （4）对于由我们与合作伙伴共同研发、提供的产品、服务，为实现您使用该产品、
          服务的目的，我们可能需要向合作伙伴共享您的部分信息。
        </b>
      </p>
      <p>
        如我们与上述任何第三方共享您的信息，我们仅会基于合法、正当、必要原则，
        在为您提供服务所必需的范围内与其共享您的信息，并且我们将努力确保第三方
        在使用您的信息时遵守本政策及我们要求其遵守的其他适当的保密和安全措施，承诺不得将您的信息用于其他任何用途。
      </p>
      <p>
        <b>
          （5）为确保部分独立功能的实现，使您能够使用和享受更多的服务及功能，
          我们会在应用中嵌入第三方的SDK，我们将审慎评估该等SDK的使用目的。
          了解更多有关SDK处理个人信息的目的、方式及范围等信息，并在至信链官网披露《第三方SDK目录》。
        </b>
      </p>
      <h3> 5.2. 【数据转移】</h3>
      <p>
        随着我们业务的持续发展，我们有可能进行分立、合并、收购、资产转让或类似的交易，
        您的信息有可能作为此类交易的一部分而被转移。我们因前述原因转移您的信息时，
        将遵守相关法律法规的要求，履行相应责任和义务。
      </p>
      <h3> 5.3. 【共享、转让、公开披露的默认情形】</h3>
      <p>根据相关法律等规定，在以下情形中，我们可以不经您同意而共享、转让、公开披露您的信息：</p>
      <p>（1） 履行法律法规规定的义务相关的，例如执行有权机关的要求。</p>
      <p>（2）与国家安全、国防安全直接相关的。 </p>
      <p>（3）与公共安全、公共卫生、重大公共利益直接相关的。 </p>
      <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的。 </p>
      <p>（5）出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。 </p>
      <p>（6）用户自行向社会公众公开的个人信息。 </p>
      <p>（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <h2>6. 信息安全</h2>
      <h3> 6.1. 【信息存储的地点】</h3>
      <p>
        我们会按照相关法律法规的规定，将在中国大陆收集的用户信息存储于<b className="z-underline">中国大陆境内</b>。
      </p>
      <h3> 6.2. 【信息存储的时间】</h3>
      <p>
        我们通常仅在为您提供服务期间保留您的信息，保存期限不会超过满足相关使用目的所必需的期限。
        <b className="z-underline">
          但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
        </b>{' '}
      </p>
      <p>（1） 遵守适用的法律法规等有关规定。 </p>
      <p>（2）遵守法院判决、裁定或其他法律程序的要求。</p>
      <p>（3）遵守相关行政、司法机关或其他有权机关的要求。</p>
      <p>（4）为执行相关服务协议或本政策、处理投诉/纠纷，或者是保护他人的人身和财产安全或合法权益所合理必需的。</p>
      <h3>6.3. 【信息安全保障】</h3>
      <p>
        {' '}
        <b className="z-underline">我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。</b>{' '}
      </p>
      <p>
        （1）我们使用各种安全技术以保障信息的安全。例如，我们将努力采取加密等安全保护措施，
        防止用户信息遭到未经授权的访问或修改、泄露、毁损或丢失。
      </p>
      <p>
        （2）我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的
        人员范围，并进行审计，要求他们遵守保密义务。{' '}
      </p>
      <p>
        （3）我们非常重视信息安全合规工作，并通过众多国际和国内的 安全认证，如 ISO 27018
        公有云个人信息保护认证、网络安全等级保护认证、ISO 27001
        信息安全管理体系认证等，以业界先进的解决方案充分保障您的信息安全。{' '}
      </p>
      <p>
        <b className="z-underline">
          （4）若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，
          按照《国家网络安全事件应急预案》等法律法规的要求及时上报，并以发送电子邮件、
          推送通知、公告等形式告知您相关情况，并向您给出安全处理建议。
        </b>{' '}
      </p>
      <h3>6.4. 【安全责任】</h3>
      <p>
        为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识和措施。
        我们仅在因至信链平台过错直接导致您信息泄露的范围内承担责任，请您妥善
        保管您的账号及密码信息，避免您的信息泄露。至信链平台账号有安全保护功能，
        但您也需要妥善保护自己的信息，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等信息。
      </p>
      <h2>7. 您如何管理自己的信息</h2>
      <h3>7.1. 【信息管理】</h3>
      <p>
        （1）<b className="z-underline">您可以访问、修改和删除您的用户信息。</b>
        为保障您的账号安全、合法权益等，不同的信息访问、修改和删除可能有不同的要求
        （例如变更实名认证信息需要进行身份验证），并且，基于技术逻辑、法律法规要求、
        保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改分配的账号ID）。
      </p>
      <p>
        （2）
        <b className="z-underline">
          在您注册完成后，您可以对账号信息进行完善、修改等，例如完善行业信息可为您提供更精准的行业优惠及活动信息。
        </b>
      </p>
      <p>
        （3）
        <b className="z-underline">
          您可以修改消息订阅设置，选择通过何种方式接收订阅消息或者选择需要订阅的消息的类别。
        </b>
        但由于推送的消息可能与您的账号安全、服务使用等息息相关（例如账户欠费通知、产品到期/回收通知等），
        我们建议您不要取消订阅，避免遗漏消息。
      </p>
      <p>
        （4）
        <b className="z-underline">
          我们将依照法律法规要求或双方约定收集、使用和存储您的信息，如我们违反法律法规的规定
          或者双方的约定收集、使用或存储您的信息，我们将进行更正或删除。
        </b>
      </p>
      <p>
        （5）
        <b className="z-underline">如果我们收集、存储的您的信息有错误，且您无法自行更正的，您可以要求我们进行更正。</b>
      </p>
      <h3>7.2. 【服务注销】</h3>
      <p>
        <b className="z-underline">您可以注销至信链平台账号。</b>
        具体操作步骤请查看账号注销帮助文档。注销成功后，该账号下所有服务、数据将被删除且无法恢复。
        当您注销账号后，我们将按照相关法律法规的要求保留您的相关信息；
        超出法定保存期限后，我们将依法删除或匿名化处理您的个人信息。
      </p>
      <h2>8. 其他</h2>
      <h3> 8.1. 【标题说明】</h3>
      <p>各条款前所列索引标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。</p>
      <h3>
        {' '}
        8.2. 【腾讯
        <a href="https://privacy.qq.com/mb/policy/tencent-privacypolicy" target="_blank" rel="noreferrer">
          《隐私政策》
        </a>
        】
      </h3>
      <p>
        腾讯
        <a href="https://privacy.qq.com/mb/policy/tencent-privacypolicy" target="_blank" rel="noreferrer">
          《隐私政策》
        </a>
        是腾讯统一适用的一般性隐私条款，其中所规定的用户权利及信息安全保障措施均适用于本平台用户。如
        <a href="https://privacy.qq.com/mb/policy/tencent-privacypolicy" target="_blank" rel="noreferrer">
          《隐私政策》
        </a>
        与本政策存在不一致或矛盾之处，请以本政策为准。
      </p>
      <h3> 8.3. 【协议持续性】</h3>
      <p>
        本协议自您勾选并成功注册为本平台用户或开始使用本平台服务之日起生效，除非本平台终止
        本协议或者用户丧失本平台用户资格，否则本协议始终有效。本服务协议终止并不免除用户
        根据本协议或其他有关协议、规则所应承担的义务和责任。
      </p>
      <h3> 8.4. 【联系我们】</h3>
      <p>
        <b className="z-underline">
          如您对本用户协议有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，
          请通过至信链官网上（https://zxchain.qq.com）的与我们联系，我们将尽快审核问题，
          并在验证用户身份后十五个工作日内容予以回复。
        </b>
      </p>
    </div>
  );
}
