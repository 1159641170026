export const nav = [
  { title: '首页', url: '/' },
  { 
    title: '浏览器', 
    url: process.env.REACT_APP_BUILD_MODE === 'test' ? 'https://zxchain-test2-4gwaufkh995d3906-1258344699.tcloudbaseapp.com/' : 'https://zxscan.qq.com', 
    target: '_blank', 
  },
  { title: '文档中心', url: '/document-center' },
  { title: '关于我们', url: '/about' },
  { title: '控制台', url: 'https://console.zxchain.qq.com/', target: '_blank' },
];
