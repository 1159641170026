import banner1Pc from './img/home/banner.png';
import banner1m from './img/home/banner-m.png';
import video from './img/home/video.png';
import excellent1 from './img/home/excellent-1.svg';
import excellent2 from './img/home/excellent-2.svg';
import excellent3 from './img/home/excellent-3.svg';
import excellent4 from './img/home/excellent-4.svg';
import recommend1 from './img/home/recommend-1.svg';
import recommend2 from './img/home/recommend-2.svg';
import recommend3 from './img/home/recommend-3.svg';
import recommend4 from './img/home/recommend-4.svg';
import logo1 from './img/home/logo-1.png';
import logo2 from './img/home/logo-2.png';
import logo3 from './img/home/logo-3.png';
import logo4 from './img/home/logo-4.png';
import tech1 from './img/home/tech-logo-1.svg';
import tech2 from './img/home/tech-logo-2.svg';
import tech3 from './img/home/tech-logo-3.svg';
import customer1 from './img/home/customer-1.svg';
import customer2 from './img/home/customer-2.svg';
import customer3 from './img/home/customer-3.svg';
import customer4 from './img/home/customer-4.svg';
import customer5 from './img/home/customer-5.svg';
import customer6 from './img/home/customer-6.svg';

import scenes0 from './img/home/scenes-金融@2x.png';
import scenes1 from './img/home/scenes-知识产权@2x.png';
import scenes2 from './img/home/scenes-文娱@2x.png';
import scenes3 from './img/home/scenes-政务@2x.png';
import scenes4 from './img/home/scenes-消费@2x.png';
import scenes5 from './img/home/scenes-贸易@2x.png';
import scenes6 from './img/home/scenes-能源@2x.png';
import scenes7 from './img/home/scenes-医疗@2x.png';
import scenes8 from './img/home/scenes-物流@2x.png';
import scenes9 from './img/home/scenes-工业@2x.png';
import scenes10 from './img/home/scenes-农业@2x.png';
import scenes11 from './img/home/scenes-教育@2x.png';

import companion1 from './img/home/companion-1.png';
import companion2 from './img/home/companion-2.png';
import companion3 from './img/home/companion-3.png';
import companion4 from './img/home/companion-4.png';
import companion5 from './img/home/companion-5.png';
import companion6 from './img/home/companion-6.png';
import companion7 from './img/home/companion-7.png';
import companion8 from './img/home/companion-8.png';
import companion9 from './img/home/companion-9.png';
import companion10 from './img/home/companion-10.png';
import companion11 from './img/home/companion-11.png';
import companion12 from './img/home/companion-12.png';
import companion13 from './img/home/companion-13.png';
import companion14 from './img/home/companion-14.png';
import companion15 from './img/home/companion-15.png';
import companion16 from './img/home/companion-16.png';
import companion17 from './img/home/companion-17.png';
import companion18 from './img/home/companion-18.png';
import companion19 from './img/home/companion-19.png';
import companion20 from './img/home/companion-20.png';
import companion21 from './img/home/companion-21.png';
import companion22 from './img/home/companion-22.png';
import companion23 from './img/home/companion-23.png';
import companion24 from './img/home/companion-24.png';
import companion25 from './img/home/companion-25.png';
import companion26 from './img/home/companion-26.png';
import companion27 from './img/home/companion-27.png';
import companion28 from './img/home/companion-28.png';
import companion29 from './img/home/companion-29.png';
import companion30 from './img/home/companion-30.png';

// banner
export const CarouseBannerData = [
  {
    imgPc: banner1Pc,
    imgMobile: banner1m,
    video: 'https://zxchain.qq.com/assets/videos/zxchain.mp4',
    videopic: video,
    link: '',
    title: 'Make value flow freely',
    des: '让价值自由流动',
  },
];
// 我们的优势
export const ExcellentData = [
  {
    img: excellent1,
    doubleImg: false, // 开启二倍图
    title: '生态开放',
    desc:
      '「至信链」开放联盟链生态中支持节点开放、协议开放、合约开放，在合规框架下追求原生区块链技术模式。',
  },
  {
    img: excellent2,
    title: '技术领先',
    desc:
      '采用国产自研、自主可控的区块链技术，拥有授权区块链专利总量超过 1000件，生产环境下最高可支持10000+TPS，同时保持稳定高可用。',
  },
  {
    img: excellent3,
    title: '严格合规',
    desc: '「至信链」经过网信办、工信部多部门备案，通过实名区块链账户技术、合约审查等手段保障强合规属性。',
  },
  {
    img: excellent4,
    title: '快速接入',
    desc: '已有成熟的区块链应用服务可供接入，同时支持自定义智能合约部署，完善的配套工具助力低成本快速用链。',
  },
];
// 推荐服务
export const RecommendData = [
  {
    title: '数字版权存证',
    desc: '为作品进行确权存证。作品创作完成即上链存证，权利人能够有效固定作品权属信息。',
    img: recommend1,
    toUrl: 'https://cloud.tencent.com/solution/tb-assurance',
  },
  {
    title: 'Hash存证',
    desc:
      '为电子证据生成Hash并上链存证，相较于传统的存证取证渠道操作更简单、成本更低廉。',
    img: recommend2,
    toUrl: 'https://cloud.tencent.com/solution/tb-assurance',
  },
  {
    title: '侵权取证',
    desc:
      '提供版权监测服务并对侵权证据进行可信取证并实时上链固定，解决权利人“侵权难追溯、维权成本高”等难题。',
    img: recommend3,
    toUrl: 'https://cloud.tencent.com/solution/tb-assurance',
  },
  {
    title: '元商品协议技术服务',
    desc:
      '支持NFT（非同质化通证）生成、流转、交易等行为的区块链服务协议，具有合规、易用、低成本的特点。',
    img: recommend4,
  },
];
// 客户案例
export const CustomerData = [
  {
    img: customer1,
    desc:
      '新华社客户端基于「至信链」，将精选的2021年新闻摄影报道铸成中国首套“新闻数字藏品”。',
    source: '新华社',
  },
  {
    img: customer2,
    desc:
      '小红书旗下「热中子工作室」推出基于「至信链」的数字空间R-SPACE。',
    source: '小红书',
  },
  {
    img: customer3,
    desc:
      '中国建设银行选择「至信链」作为区块链存证服务商，将其集成进“快贷”服务端业务系统，以更好地实现区块链存证，其证据被各地法院广泛采信',
    source: '中国建设银行',
  },
  {
    img: customer4,
    desc:
      '敦煌研究院基于「至信链」策划了“点亮敦煌”活动，用户领取独一无二的数字藏品福卡，活动吸引了超3000万人参与，有效助力文化传播。',
    source: '敦煌研究院',
  },
  {
    img: customer5,
    desc:
      '阅文基于「至信链」元商品协议，打造立足IP生态链的数字藏品平台，现已发行国内首个网文数字藏品。',
    source: '阅文集团',
  },
  {
    img: customer6,
    desc: '人民文创基于「至信链」发起“新春送福，福满全球”活动，首次面向全球发行青花瓷“福”文化数字藏品。',
    source: '人民文创',
  },
];

export const CompanionData = [
  companion1,
  companion2,
  companion3,
  companion4,
  companion5,
  companion6,
  companion7,
  companion8,
  companion9,
  companion10,
  companion11,
  companion12,
  companion13,
  companion14,
  companion15,
  companion16,
  companion17,
  companion18,
  companion19,
  companion20,
  companion21,
  companion22,
  companion23,
  companion24,
  companion25,
  companion26,
  companion27,
  companion28,
  companion29,
  companion30,
];

// 公信力节点
export const CredibilityData = [
  {
    img: logo1,
    subTitle: '四川省高级人民法院',
  },
  {
    img: logo2,
    subTitle: '国家工业信息安全发展研究中心',
  },
  {
    img: logo3,
    subTitle: '深圳市中级人民法院',
  },
  {
    img: logo4,
    subTitle: '深圳前海公证处',
  },
];
export const  TechSupportData = [
  {
    img: tech1,
    subTitle: '新一代自主可控区块链技术体系',
    toUrl:"https://chainmaker.org.cn/home",
  },
  {
    img: tech2,
    subTitle: '腾讯云区块链服务平台TBaaS',
    toUrl:"https://cloud.tencent.com/product/tbaas",
  },
  {
    img: tech3,
    subTitle: '腾讯云区块链分布式身份TDID',
    toUrl:"https://cloud.tencent.com/product/tdid",
  },
];

// 统计
export const StatisticsData = [
  { title: '累计交易量', number: '1,000,000', desc: '已上链的总交易笔数' },
  { title: '地址数', number: '1,000,000', desc: '链上地址总数' },
  { title: '区块高度', number: '38918290', desc: '链上最新的区块的序号' },
  {
    title: '交易速度',
    number: '1300',
    desc: '每分钟可以处理的交易笔数',
  },
];

// 应用场景
export const ScenesData = [
  {
    icon: scenes0,
    title: '金融',
    desc:
      '在线上金融业务发生时对关键证据（如合同及收付凭证）进行区块链存证，保障电子证据的真实性，同时保护交易数据原文等商业秘密。',
    lable: ['供应链金融 ', '贸易融资', '资金管理', '支付清算', '数字资产'],
  },
  {
    icon: scenes1,
    title: '知识产权',
    desc:
      '基于区块链技术，为作品进行确权存证，并及时固定侵权证据，打造版权全生命周期的保护方案。',
    lable: ['音乐创作 ', '平面设计版权存证 '],
  },
  {
    icon: scenes2,
    title: '文娱',
    desc:
      '基于区块链搭建的去中介化、更公开透明的数字商品交易平台，能为文娱IP的商业化提供增量空间；链上数据共通的特性，还能为应用层的业务模式提供更多想象空间。',
    lable: ['游戏 ', '影视版权交易', '文化IP数字商品'],
  },
  {
    icon: scenes3,
    title: '政务',
    desc:
      '可推进公共治理过程的多方数据共享、高效协同服务，过程公开可信。',
    lable: ['数字身份平台 ', '政务数据共享平台', '电子票据'],
  },
  {
    icon: scenes4,
    title: '消费',
    desc:
      '利用区块链技术让供应链上各环节共享可信数据，助力消费品溯源。',
    lable: ['食品防伪溯源 '],
  },
  {
    icon: scenes5,
    title: '贸易',
    desc:
      '通过智能合约执行贸易规则，提高了贸易的自动化水平，创造了高效、可信的履约环境，从而加速生产要素的全球性流动。',
    lable: ['动态竞价 ', '结算兑现', '跨境贸易'],
  },
  {
    icon: scenes6,
    title: '能源',
    desc:
      '区块链技术不仅为能源交易提供了更可信、更安全的交易环境，还通过智能合约实现交易自动化，提高了交易效率。',
    lable: ['碳交易 '],
  },
  {
    icon: scenes7,
    title: '医疗',
    desc:
      '区块链技术可从效率提升、数据共享、协同管理、医疗供应链金融等方面解决医疗行业的痛点。',
    lable: ['电子病历 ', '保险风控'],
  },
  {
    icon: scenes8,
    title: '物流',
    desc:
      '基于区块链技术，记录商品的物流详情、理赔等信息，实现全链路监管和信息共享；基于智能合约实现商品流转、签收、理赔的自动化，提高商品流转效率。',
    lable: ['物流数据记录 ', '行业黑名单共享', '安全事件监管'],
  },
  {
    icon: scenes9,
    title: '工业',
    desc:
      '基于区块链不可篡改、分布式的特性，实现安全有效的上下游信息共享，对工业品的生产全流程进行监控，为工业高质量发展带来新机遇。',
    lable: ['分布式生产 ', '设备访问控制', '设备融资租赁'],
  },
  {
    icon: scenes10,
    title: '农业',
    desc:
      '利用区块链技术发展智慧农业，基于IoT、GPS数据，完善农业品生产的过程监控，提高农产品作业效率和产品质量。',
    lable: ['智慧农场 ', '农产品溯源'],
  },
  {
    icon: scenes11,
    title: '教育',
    desc:
      '在教育领域，区块链技术在资质证明、学术成果保护等方面均有的放矢。不仅能为师生的资质能力提供可信证明，也为学术资源的版权保护提供有力凭证。',
    lable: ['资格证书验证 ', '学术资源版权保护'],
  },
];
