import React, { useState, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mPrefix } from '../../utility';
import Media from '../../components/Media';
import PropTypes from 'prop-types';
import './index.scss';
import useClientType from '../../hooks/useClientType';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default function CarouselCustomer(props) {
  const {
    data = [],
    spaceBetween = 0,
    showPagination = false,
    autoplay = false,
    loop = true,
    navigation = true,
  } = props;
  // 移动端显示一屏多大
  const { isMobile } = useClientType();
  const [showScreen, setShowScreen] = useState(isMobile ? 1.2 : 3);
  useEffect(() => {
    if (isMobile) {
      setShowScreen(1.2);
    } else {
      setShowScreen(3);
    }
  }, [isMobile]);
  return (
    <div className={`${mPrefix}-swiper ${mPrefix}-carouselCustomer`}>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={showScreen}
        mousewheel={true}
        loop={loop && data.length > showScreen}
        pagination={showPagination && data.length > 1 && { clickable: true }}
        navigation={
          navigation && {
            nextEl: '.swiper-button-next',
            disabledClass: 'disabled',
            prevEl: '.swiper-button-prev',
          }
        }
        autoplay={
          // eslint-disable-next-line prettier/prettier
          autoplay
          // eslint-disable-next-line prettier/prettier
          && data.length > showScreen && {
            observeParents: true,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            autoplayDisableOnInteraction: false,
            delay: 3000,
            speed: 3000,
            observer: true,
          }
        }
      >
        {data.map((item, index) => (
          <SwiperSlide className={`${isMobile ? '' : 'swiper-no-swiping'}`} key={`swiper-${index}`}>
            <div className="swiper-box-item">
              <Media data={item} radius card hover horizon shadow />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {data.length > showScreen && (
        <div className={`${mPrefix}-swiper-button`}>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      )}
    </div>
  );
}
CarouselCustomer.propTypes = {
  data: PropTypes.array,
  spaceBetween: PropTypes.number,
  showPagination: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  navigation: PropTypes.bool,
};
