import React from 'react';
import classNames from 'classnames';
import { Prefix, PublicContent } from '../../utility';
import PropTypes from 'prop-types';
import './index.scss';

export function Row(props) {
  const {
    className, // 自定义类名
    style = {}, // 自定义样式
    width, // 宽度
    gutter, // 间隔 [左右间隔， 上下间隔]
    overflow,
    ...rest // 其他自定义属性
  } = props;

  const cls = classNames(`${Prefix}-row`, [className]);

  if (width) {
    style.width = `${width}px`;
  }

  if (gutter) {
    const x = gutter[0] || 0;
    const y = gutter[1] || 0;
    style.margin = `-${y / 2}px -${x / 2}px -${y / 2}px -${x / 2}px`;
  }

  return (
    <PublicContent.Provider value={gutter}>
      {overflow ? (
        <div className={`${Prefix}-grid`}>
          <div className={cls} style={style} {...rest}>
            {props.children}
          </div>
        </div>
      ) : (
        <div className={cls} style={style} {...rest}>
          {props.children}
        </div>
      )}
    </PublicContent.Provider>
  );
}

Row.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.number,
  gutter: PropTypes.array,
  overflow: PropTypes.bool,
  children: PropTypes.element,
};
