import { useState, useEffect } from 'react';
const useClientType = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth < 768);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
  return {
    isMobile,
    windowHeight,
  };
};
export default useClientType;
