import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import Section from '../../components/Section';
import { Header, Footer, CarouselHonor, CarouselRecord } from '../../containers/index';

import './index.scss';
import { CarouselHonorData, CarouselRecordData } from '../../mock/about.js';
function About() {
  return (
    <Fragment>
      <Header />
      <Banner className="about-page-hero" style={{ backgroundColor: '#000' }}>
        <div className="about-page-hero-info">
          <div className="about-page-hero-info__title">关于我们</div>
          <div className="about-page-hero-info__desc">
            <p>
              「至信链」是腾讯公司联合外部生态伙伴发布的区块链开放平台，基于国产开源自主可控的「长安链」技术底层建设，已有十余家社会各界公信力机构作为节点加入。
            </p>
            <p>「至信链」开放联盟链旨在为价值互联网提供低门槛的上链方案，让信息成为资产，让价值自由流动。</p>
          </div>
        </div>
      </Banner>
      <div className="about-page-hero-introduce show-moblie">
        <p>
          「至信链」是腾讯公司联合外部生态伙伴发布的区块链开放平台，基于国产开源自主可控的「长安链」技术底层建设，已有十余家社会各界公信力机构作为节点加入。
        </p>
        <p>「至信链」开放联盟链旨在为价值互联网提供低门槛的上链方案，让信息成为资产，让价值自由流动。</p>
      </div>
      <Section title={'资质荣誉'} className="bg-gray hidden about-section--carouselHonor ">
        <CarouselHonor data={CarouselHonorData} slidesPerView={3} />
      </Section>
      <Section title={'大事记'} className="about-time hidden">
        <CarouselRecord data={CarouselRecordData} showPagination={true} />
      </Section>
      <Footer />
    </Fragment>
  );
}

export default About;
