import React, { useCallback, useEffect, useState } from 'react';
import { nav } from '../../mock/header';
import { mPrefix } from '../../utility/js/Prefix';
import logo from '../../mock/img/head/logo.png';

import './index.scss';
import useClientType from '../../hooks/useClientType';
let animateId = null;
const ph = (92 / 224) * 104;
function Header() {
  const [navBtn, setNavBtn] = useState(false);
  const [positionTop, setPositionTop] = useState(0);
  const { isMobile } = useClientType();
  const handleShowNav = () => {
    if (navBtn) {
      setNavBtn(false);
    } else {
      setNavBtn(true);
    }
  };
  const step = useCallback(() => {
    let top = positionTop;
    if (top > 2435) {
      top = 0;
    } else {
      top = top + ph;
    }
    setPositionTop(top);
  }, [positionTop]);
  useEffect(() => {
    if (isMobile) {
      cancelAnimationFrame(animateId);
    } else {
      animateId = requestAnimationFrame(step);
    }
  }, [step, isMobile]);
  useEffect(
    () => () => {
      cancelAnimationFrame(animateId);
    },
    [],
  );
  return (
    <section className={`${mPrefix}-header`}>
      <div className={`${mPrefix}-header__inner`}>
        <h1 className={`${mPrefix}-header__logo`}>
          <a href="/">
            <img src={logo} alt="至信链" />
          </a>
        </h1>
        <nav className={`${mPrefix}-header__nav ${navBtn ? 'is-active' : ''}`}>
          <ol>
            {nav.map((item, index) => {
              if (index !== nav.length - 1) {
                return (
                  <li key={`nav-${index}`}>
                    <a href={item.url} className="title">
                      {item.title}
                    </a>
                  </li>
                );
              }
              return (
                <li
                  key={`nav-${index}`}
                  className={isMobile ? '' : `${mPrefix}-header__navitem `}
                  style={isMobile ? {} : { backgroundPosition: `0 ${positionTop}px` }}
                >
                  <a href={item.url} className="title">
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ol>
        </nav>
        <div className={`${mPrefix}-header__nav-btn ${navBtn ? 'is-active' : ''}`} onClick={handleShowNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
}

export default Header;
