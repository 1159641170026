import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Prefix } from '../../utility';
import './index.scss';

function Banner(props) {
  const { className, style, children, ...rest } = props;
  const cls = classNames(`${Prefix}-hero`, className);
  return (
    <section className={cls} style={style} {...rest}>
      <div className={`${Prefix}-hero__inner`}>{children}</div>
    </section>
  );
}

Banner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.element,
};

export default Banner;
