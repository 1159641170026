import axios from 'axios';

const BASEURL_TEST = 'https://testadminapi.zxchain.qq.com';
const BASEURL_PRD = 'https://zxscanapi.qq.com';

function request({ method, params, data, ...rest }) {
  const axiosRequestConfig = {
    method: method || 'GET',
    baseURL: process.env.REACT_APP_BUILD_MODE === 'test' ? BASEURL_TEST : BASEURL_PRD,
    params,
    data,
    ...rest,
  };

  return new Promise((resolve, reject) => {
    axios(axiosRequestConfig)
      .then((res) => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export default request;
